import React from 'react';

import { ContentWrapper } from './ContentWrapper';
import { cx } from '../utils/cx';

type DividerProps = {
  className?: string;
};

export const Divider = (props: DividerProps) => {
  return (
    <div aria-hidden="true">
      <ContentWrapper className={cx('overflow-hidden', props.className ?? '')}>
        <svg
          width="800"
          height="4"
          viewBox="0 0 800 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-primary-100 dark:stroke-gray-700"
        >
          <rect
            x="1"
            y="1"
            width="798"
            height="2"
            strokeWidth="2"
            strokeDasharray="2 2"
          />
        </svg>
      </ContentWrapper>
    </div>
  );
};
