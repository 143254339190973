import React from 'react';

import { cx } from '../utils/cx';

export const Overhead = (props: {
  children?: string | null;
  className?: string;
}) => (
  <span
    className={cx(
      'block font-mono font-semibold uppercase tracking-wide text-sm',
      props.className ?? ''
    )}
  >
    {props.children}
  </span>
);
