import React from 'react';

import { Link } from 'gatsby';

import { BorderBackground } from '../BorderBackground';
import { Overhead } from '../Overhead';
import { cx } from '../../utils/cx';

type BlogPostCardProps = {
  details: Queries.BlogPostFrontmatterFragment;
  expanded?: boolean;
};

export const BlogPostCard = (props: BlogPostCardProps) => {
  const frontmatter = props.details.frontmatter;
  if (!frontmatter?.title || !frontmatter?.slug) return null;

  const additionalCardClasses = props.expanded
    ? 'w-full p-4'
    : 'p-3 max-w-[280px]';

  const additionalTitleClasses = props.expanded
    ? 'text-base font-medium mt-4 mb-2'
    : 'text-sm font-normal mt-2 mb-4';

  const additionalDateClasses = props.expanded ? 'text-right' : 'text-left';

  return (
    <Link
      className="relative overflow-hidden inline-block whitespace-normal mr-4 md:mr-0"
      to={`/blog/${frontmatter.slug}`}
    >
      <div className="relative m-1 shadow-default rounded-md">
        <BorderBackground shape="rectangle" />
        <article
          className={cx(
            'relative bg-clip-padding bg-gray-100 dark:bg-gray-900 rounded-md border-[4px] border-transparent hover:border-primary-50/80 dark:hover:border-gray-800/80 transition-border duration-200',
            additionalCardClasses
          )}
        >
          <Overhead
            data-article-category
            className="block text-xs text-primary-900 dark:text-primary-50"
          >
            {frontmatter.category}
          </Overhead>
          <span
            data-article-title
            className={cx(
              'block text-primary-900 dark:text-primary-50',
              additionalTitleClasses
            )}
          >
            {frontmatter.title}
          </span>
          {props.expanded ? (
            <span
              data-article-summary
              className="block text-sm mb-4 text-gray-500 dark:text-gray-500"
            >
              {frontmatter.summary}
            </span>
          ) : null}
          <span
            data-article-published
            className={cx(
              'block font-medium text-xs text-gray-400 dark:text-gray-600',
              additionalDateClasses
            )}
          >
            {frontmatter.date}
          </span>
        </article>
      </div>
    </Link>
  );
};
