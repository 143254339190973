import React from 'react';

export const GithubSVG = (
  <svg
    viewBox="0 0 24 24"
    height="48"
    width="48"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Github"
  >
    <path d="M12 1A10.89 10.89 0 001 11.77 10.79 10.79 0 008.52 22c.55.1.75-.23.75-.52v-1.83c-3.06.65-3.71-1.44-3.71-1.44a2.86 2.86 0 00-1.22-1.58c-1-.66.08-.65.08-.65a2.31 2.31 0 011.68 1.11 2.37 2.37 0 003.2.89 2.33 2.33 0 01.7-1.44c-2.44-.27-5-1.19-5-5.32a4.15 4.15 0 011.11-2.91 3.78 3.78 0 01.11-2.84s.93-.29 3 1.1a10.68 10.68 0 015.5 0c2.1-1.39 3-1.1 3-1.1a3.78 3.78 0 01.11 2.84A4.15 4.15 0 0119 11.2c0 4.14-2.58 5.05-5 5.32a2.5 2.5 0 01.75 2v2.95c0 .35.2.63.75.52A10.8 10.8 0 0023 11.77 10.89 10.89 0 0012 1"></path>
  </svg>
);

export const LinkedInSVG = (
  <svg
    viewBox="0 0 448 512"
    height="48"
    width="48"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="LinkedIn"
  >
    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
  </svg>
);

export const TwitterSVG = (
  <svg
    viewBox="0 0 24 24"
    height="48"
    width="48"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Twitter"
  >
    <path d="M8.08 20A11.07 11.07 0 0 0 19.52 9 8.09 8.09 0 0 0 21 6.16a.44.44 0 0 0-.62-.51 1.88 1.88 0 0 1-2.16-.38 3.89 3.89 0 0 0-5.58-.17A4.13 4.13 0 0 0 11.49 9C8.14 9.2 5.84 7.61 4 5.43a.43.43 0 0 0-.75.24 9.68 9.68 0 0 0 4.6 10.05A6.73 6.73 0 0 1 3.38 18a.45.45 0 0 0-.14.84A11 11 0 0 0 8.08 20"></path>
  </svg>
);

export const RssSVG = (
  <svg
    viewBox="0 0 100 100"
    height="48"
    width="48"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Rss"
  >
    <path d="M26.258 64.949a8.78 8.78 0 0 0-8.78 8.784 8.782 8.782 0 1 0 17.564 0 8.78 8.78 0 0 0-8.784-8.784z"></path>
    <path d="M23.536 40.801c-.046 0-.09.006-.135.007v-.007h-3.464v.039a3.437 3.437 0 0 0-3.056 3.344h-.007v6.159h.041a3.43 3.43 0 0 0 3.021 3.002v.039H23.4v-.048c.045.001.09.007.135.007 12.772 0 23.173 10.321 23.311 23.061h-.033v3.464h.039a3.437 3.437 0 0 0 3.344 3.056v.007h6.158v-.041a3.43 3.43 0 0 0 3.002-3.021h.039v-3.464h-.006c-.137-19.657-16.166-35.604-35.853-35.604z"></path>
    <path d="M83.119 76.403C82.98 43.664 56.308 17.07 23.536 17.07c-.046 0-.09.006-.135.007v-.007h-3.464v.039a3.437 3.437 0 0 0-3.056 3.344h-.007v6.159h.041a3.429 3.429 0 0 0 3.021 3.002v.039H23.4v-.048c.045.001.09.007.135.007 25.857 0 46.902 20.967 47.041 46.792h-.035v3.464h.039a3.437 3.437 0 0 0 3.344 3.056v.007h6.159v-.041a3.43 3.43 0 0 0 3.002-3.021h.039v-3.464h-.005z"></path>
  </svg>
);
