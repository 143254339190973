import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { RssSVG } from './Icons';
import { ExternalLink } from './ExternalLink';

export const SubscribeRss = () => {
  const data = useStaticQuery<Queries.RssFeedQuery>(query);

  const rssFeedPath = data.allContentJson.nodes[0].rssFeed?.path;
  if (!rssFeedPath) return null;

  const siteUrl =
    process.env.NODE_ENV !== 'development'
      ? data.allContentJson.nodes[0].defaultSEO?.siteUrl
      : 'http://localhost:8000';

  const Icon = React.cloneElement(RssSVG, {
    className: 'w-[18px] h-[18px]',
  });

  return (
    <div>
      <ExternalLink
        href={`${siteUrl}${rssFeedPath}`}
        className="link text-sm inline-flex items-center justify-start gap-2 fill-gray-500 hover:fill-gray-900 dark:fill-gray-400 dark:hover:fill-gray-50"
      >
        {Icon} Subscribe RSS
      </ExternalLink>
    </div>
  );
};

const query = graphql`
  query RssFeed {
    allContentJson {
      nodes {
        rssFeed {
          title
          path
        }
        defaultSEO {
          siteUrl
        }
      }
    }
  }
`;
