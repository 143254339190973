import React, { useMemo, useState } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { Layout } from '../../components/Layout';
import { SEO } from '../../components/SEO';
import { ContentWrapper } from '../../components/ContentWrapper';
import { SubscribeRss } from '../../components/SubscribeRss';
import { BlogPostCard } from '../../components/Cards/BlogPost';
import { SearchInput } from '../../components/SearchInput';
import { Button } from '../../components/Button';
import { Divider } from '../../components/Divider';
import { useSearchIndex } from '../../hooks/useSearchIndex';
import { useDebounce } from '../../hooks/useDebounce';
import { cx } from '../../utils/cx';

const BlogPostListing = () => {
  const data = useStaticQuery<Queries.AllBlogPostsQuery>(query);
  const posts = [...data.allMarkdownRemark.nodes] ?? [];

  const [shownPosts, setShownPosts] = useState<number>(12);

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const searchIndex = useSearchIndex(posts, [
    { name: 'frontmatter.title', weight: 0.7 },
    { name: 'frontmatter.summary', weight: 0.5 },
    { name: 'frontmatter.tags', weight: 0.5 },
    { name: 'frontmatter.category', weight: 0.2 },
  ]);

  const queriedPosts = useMemo(() => {
    return debouncedSearchQuery
      ? searchIndex.search(debouncedSearchQuery).map(result => result.item)
      : posts;
  }, [posts, debouncedSearchQuery, searchIndex]);

  const hasMorePosts = shownPosts < queriedPosts.length;

  return (
    <Layout>
      <ContentWrapper className="mt-8 mb-40">
        <h1 className="text-4xl">Blog</h1>
        <p className="max-w-xl my-10 text-gray-500 dark:text-gray-400">
          I regularly write about software, hardware, and security. After work,
          I like playing around with all sorts of stuff – this is what you will
          find here.
        </p>
        <SubscribeRss />
        <SearchInput
          value={searchQuery}
          onChange={({ target }) => setSearchQuery(target.value)}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-14">
          {queriedPosts.length ? (
            queriedPosts
              .slice(0, shownPosts)
              .map((node, key) => (
                <BlogPostCard details={node} key={key} expanded />
              ))
          ) : (
            <p className="text-gray-500 dark:text-gray-500">No posts found.</p>
          )}
        </div>
        <div className="w-full flex flex-col md:flex-row md:items-center gap-12 md:gap-6 overflow-hidden">
          <Divider
            className={cx('px-0', hasMorePosts ? 'w-[30rem]' : 'w-[40rem]')}
          />
          <div className="flex-shrink-0 flex-grow flex justify-end items-center gap-6">
            <span
              id="post-count"
              className="text-sm text-gray-400 dark:text-gray-600"
            >
              {hasMorePosts ? shownPosts : queriedPosts.length} of{' '}
              {queriedPosts.length} seen
            </span>
            {hasMorePosts ? (
              <Button
                id="show-more"
                type="button"
                onAction={() => setShownPosts(shownPosts + 12)}
              >
                Show more
              </Button>
            ) : undefined}
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
};

export default BlogPostListing;

export const Head = () => <SEO title="Blog – Brendan Horan" />;

const query = graphql`
  query AllBlogPosts {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        ...BlogPostFrontmatter
      }
    }
  }
`;
