import React from 'react';

import { Link } from 'gatsby';

type ButtonProps = {
  id?: string;
  type: 'button' | 'link';
  children: React.ReactElement | string;
  onAction: (() => void) | string;
};

export const Button = (props: ButtonProps) => {
  const className =
    'py-3 px-6 shadow-default bg-primary-700 text-white hover:bg-primary-600 dark:bg-primary-200 dark:text-black dark:hover:bg-primary-300 text-sm font-medium rounded-md transition-all duration-200';

  if (props.type === 'link' && typeof props.onAction === 'string') {
    return (
      <Link id={props.id} to={props.onAction} className={className}>
        {props.children}
      </Link>
    );
  }

  if (props.type === 'button' && typeof props.onAction === 'function') {
    return (
      <button
        id={props.id}
        type="button"
        onClick={props.onAction}
        className={className}
      >
        {props.children}
      </button>
    );
  }

  throw new Error('Please specify a correct handler/link target.');
};
